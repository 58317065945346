import notification from 'antd/lib/notification';
import React from 'react';

const showError = (title, _error = '') => {
  const error = _error.toString();
  notification.error({
    message: (
      <div
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
    ),
    duration: 7,
    description:
      error.length > 200 ? 'Open the Browser Console to get details' : error,
  });
};

const showMessage = (title) => {
  notification.info({
    message: (
      <div
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
    ),
    duration: 5,
  });
};

export { showError, showMessage };
