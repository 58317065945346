import { Select } from 'antd';
import React from 'react';
const { Option } = Select;

const SelectControl = (props) => {
  const { handleChange, options } = props;

  return (
    <Select
      mode='multiple'
      style={{
        width: '100%',
      }}
      placeholder='CSV出力の列を選択する'
      defaultValue={[]}
      onChange={handleChange}
      optionLabelProp='label'
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value} label={option.label}>
          <div className='demo-option-label-item'>{option.label}</div>
        </Option>
      ))}
    </Select>
  );
};
export default SelectControl;
