import { AuthActionTypes } from '../actions/auth-actions';

const defaultState = {
  initialized: false,
  fetching: false,
  user: null,
  authActionsFetching: false,
  authActionsInitialized: false,
  allowChangePassword: false,
  showChangePasswordDialog: false,
  allowResetPassword: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultState, action) {
  switch (action.type) {
    case AuthActionTypes.AUTHORIZED_SUCCESS:
      return {
        ...state,
        initialized: true,
        user: action.payload.user,
      };
    case AuthActionTypes.AUTHORIZED_FAILED:
      return {
        ...state,
        initialized: true,
      };
    case AuthActionTypes.LOGIN:
      return {
        ...state,
        fetching: true,
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        user: action.payload.user,
      };
    case AuthActionTypes.LOGIN_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        fetching: true,
      };
    case AuthActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        fetching: false,
        user: null,
      };
    case AuthActionTypes.LOAD_AUTH_ACTIONS:
      return {
        ...state,
        authActionsFetching: true,
      };
    case AuthActionTypes.LOAD_AUTH_ACTIONS_SUCCESS:
      return {
        ...state,
        authActionsFetching: false,
        authActionsInitialized: true,
        allowChangePassword: action.payload.allowChangePassword,
        allowResetPassword: action.payload.allowResetPassword,
      };
    case AuthActionTypes.LOAD_AUTH_ACTIONS_FAILED:
      return {
        ...state,
        authActionsFetching: false,
        authActionsInitialized: true,
        allowChangePassword: false,
        allowResetPassword: false,
      };
    default:
      return state;
  }
}
