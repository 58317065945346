export const NotificationsActionType = {
  RESET_ERRORS : 'RESET_ERRORS',
  RESET_MESSAGES : 'RESET_MESSAGES',
}

export function resetErrors() {
  const action = {
    type: NotificationsActionType.RESET_ERRORS,
    payload: {},
  };

  return action;
}

export function resetMessages() {
  const action = {
    type: NotificationsActionType.RESET_MESSAGES,
    payload: {},
  };

  return action;
}
