import { Button, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import SelectControl from '../controls/select-control';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const SelectColumnExportModal = (props) => {
  const { csvData, handleChange } = props;
  const [modal2Open, setModal2Open] = useState(false);
  const [csvName, setCsvName] = useState('');

  const handleCloseModal = () => {
    const requestTime = moment(new Date()).format('YYYY-MM-DD_HH-mm');
    setCsvName(`荷物追跡_${requestTime}.csv`);
    setTimeout(() => {
      setModal2Open(false);
    }, 1000);
  };

  useEffect(() => {
    handleChange([]);
  }, []);

  return (
    <>
      <Button type='primary' className='ant-btn ant-btn-primary ant-btn-lg' onClick={() => setModal2Open(true)}>
        CSV出力（列選択）
      </Button>
      <Modal
        title='CSV出力の列選択'
        centered
        maskClosable={false}
        visible={modal2Open}
        onOk={handleCloseModal}
        onCancel={() => setModal2Open(false)}
        footer={[
          <Button
            key='back'
            className='ant-btn ant-btn-primary ant-btn-lg'
            style={{
              marginRight: '20px',
            }}
            onClick={() => setModal2Open(false)}
          >
            キャンセル
          </Button>,
          <CSVLink
            className={`ant-btn ant-btn-primary ant-btn-lg`}
            data={csvData}
            filename={csvName}
            target='_blank'
            style={{
              marginRight: '20px',
            }}
            onClick={handleCloseModal}
          >
            CSV出力
          </CSVLink>,
        ]}
      >
        <SelectControl
          handleChange={handleChange}
          options={[
            { value: '業者名', label: '問合番号' },
            { value: '問合番号', label: '業者名' },
            { value: '問合結果', label: '問合結果' },
            { value: '問合時間', label: '問合時間' },
            { value: '結果時間', label: '結果時間' },
            { value: '最終配達ターミナル名', label: '最終配達ターミナル名' },
            { value: '問合せ電話番号', label: '問合せ電話番号' },
            { value: '各社サイト', label: '各社サイト' },
          ]}
        />
      </Modal>
    </>
  );
};
export default SelectColumnExportModal;
