import Icon from '@ant-design/icons';
import { Card, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { DetailIcon } from '../../icons';

export default function DeliverInfoListMobile(props) {
  const { csvData } = props;

  const openNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Space direction='vertical' size='large' style={{ width: '100%' }}>
      {csvData.map((data) => (
        <Card
          key={data['問合番号']}
          title={`問合番号：${data['問合番号']}`}
          extra={
            <Icon
              component={DetailIcon}
              onClick={() => openNewTab(data['各社サイト'])}
            />
          }
          style={{ width: '100%' }}
        >
          <Space direction='vertical' size='large'>
            <Text>業者名：{data['業者名']}</Text>
            <Text>問合結果：{data['問合結果']}</Text>
            <Text>問合時間：{data['問合時間']}</Text>
            <Text>結果時間：{data['結果時間']}</Text>
            <Text>最終配達ターミナル名：{data['最終配達ターミナル名']}</Text>
            <Text>問合せ電話番号：{data['問合せ電話番号']}</Text>
          </Space>
        </Card>
      ))}
    </Space>
  );
}
