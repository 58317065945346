import { CaretDownOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import Dropdown from 'antd/lib/dropdown';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { Link } from 'react-router-dom';
import { hasPermission } from '../../utils/utils';
function ResponsiveMenu(props) {
  const { user, menu } = props;
  const isShowMenu = hasPermission(user, 2);
  return (
    <>
      {isShowMenu && (
        <Row>
          <Link to='/upload-transport-data' target='_blank'>
            <Text className='delivery-header-text' strong>
              業者コード紐付け
            </Text>
          </Link>
        </Row>
      )}

      <Row>
        <Link to='/delivery-list-trial' target='_blank'>
          <Text className='delivery-header-text' strong>
            簡易検索
          </Text>
        </Link>
      </Row>
      <Dropdown overlay={menu} className='delivery-header-menu-responsive-dropdown'>
        <span>
          <Text className='delivery-header-text' strong>
            {user.firstName.length > 14 ? `${user.firstName.slice(0, 10)} ...` : user.firstName}
          </Text>
          <CaretDownOutlined className='delivery-header-menu-icon' />
        </span>
      </Dropdown>
    </>
  );
}

export default ResponsiveMenu;
