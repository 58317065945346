/* eslint-disable no-new-func */

export function isDev() {
  return process.env.NODE_ENV === 'development';
}

export function isPublic() {
  return process.env.PUBLIC_INSTANCE === 'true';
}

export function customWaViewHit(pageName, queryString, hashInfo) {
  const waHitFunctionName = process.env.WA_PAGE_VIEW_HIT;
  if (waHitFunctionName) {
    const waHitFunction = new Function(
      'pageName',
      'queryString',
      'hashInfo',
      `if (typeof ${waHitFunctionName} === 'function') {
                ${waHitFunctionName}(pageName, queryString, hashInfo);
            }`
    );
    try {
      waHitFunction(pageName, queryString, hashInfo);
    } catch (error) {
      // eslint-disable-next-line
      console.error(
        `Web analitycs hit function has failed. ${error.toString()}`
      );
    }
  }
}
