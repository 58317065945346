import { ConfigProvider } from 'antd';
import jaJp from 'antd/lib/locale/ja_JP';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createRootReducer from './reducers/index';
import { authorizedAsync, loadAuthActionsAsync } from './actions/auth-actions';
import { resetErrors, resetMessages } from './actions/notification-actions';
import DeliverApplication from './components/delivery-app';
import createStore, { getStore } from './store/store';

createStore(createRootReducer);
const store = getStore();

function mapStateToProps(state) {
  const { auth } = state;
  return {
    userInitialized: auth.initialized,
    userFetching: auth.fetching,
    authActionsFetching: auth.authActionsFetching,
    authActionsInitialized: auth.authActionsInitialized,
    allowChangePassword: auth.allowChangePassword,
    allowResetPassword: auth.allowResetPassword,
    notifications: state.notifications,
    user: auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verifyAuthorized: () => dispatch(authorizedAsync()),
    resetErrors: () => dispatch(resetErrors()),
    resetMessages: () => dispatch(resetMessages()),
    loadAuthActions: () => dispatch(loadAuthActionsAsync()),
  };
}

const ReduxAppWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliverApplication);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ConfigProvider locale={jaJp}>
        <ReduxAppWrapper />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
