import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { isDev } from '../utils/environment';

const logger = createLogger({
  predicate: isDev,
  collapsed: true,
});

const middlewares = [thunk, logger];

let store = null;

export default function createDeliveryStore(createRootReducer) {
  let appliedMiddlewares = applyMiddleware(...middlewares);

  if (isDev()) {
    const { composeWithDevTools } = require('redux-devtools-extension');

    appliedMiddlewares = composeWithDevTools(appliedMiddlewares);
  }

  store = createStore(createRootReducer(), appliedMiddlewares);
}

export function getStore() {
  if (store) {
    return store;
  }

  throw new Error('First create a store');
}
