import isReachable from '../utils/url-checker';
import {
  authorized,
  getUsers,
  login,
  logout,
  resetAuthorization,
} from '../services/api-service';
import { createAction } from '../utils/redux';

export const AuthActionTypes = {
  AUTHORIZED_SUCCESS: 'AUTHORIZED_SUCCESS',
  AUTHORIZED_FAILED: 'AUTHORIZED_FAILED',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILED: 'LOGOUT_FAILED',
  LOAD_AUTH_ACTIONS: 'LOAD_AUTH_ACTIONS',
  LOAD_AUTH_ACTIONS_SUCCESS: 'LOAD_AUTH_ACTIONS_SUCCESS',
  LOAD_AUTH_ACTIONS_FAILED: 'LOAD_AUTH_ACTIONS_FAILED',
};

export const authActions = {
  authorizeSuccess: (user) =>
    createAction(AuthActionTypes.AUTHORIZED_SUCCESS, { user }),
  authorizeFailed: (error) =>
    createAction(AuthActionTypes.AUTHORIZED_FAILED, { error }),
  login: () => createAction(AuthActionTypes.LOGIN),
  loginSuccess: (user) => createAction(AuthActionTypes.LOGIN_SUCCESS, { user }),
  loginFailed: (error) => createAction(AuthActionTypes.LOGIN_FAILED, { error }),
  logout: () => createAction(AuthActionTypes.LOGOUT),
  logoutSuccess: () => createAction(AuthActionTypes.LOGOUT_SUCCESS),
  logoutFailed: (error) =>
    createAction(AuthActionTypes.LOGOUT_FAILED, { error }),
  loadServerAuthActions: () => createAction(AuthActionTypes.LOAD_AUTH_ACTIONS),
  loadServerAuthActionsSuccess: (allowChangePassword, allowResetPassword) =>
    createAction(AuthActionTypes.LOAD_AUTH_ACTIONS_SUCCESS, {
      allowChangePassword,
      allowResetPassword,
    }),
  loadServerAuthActionsFailed: (error) =>
    createAction(AuthActionTypes.LOAD_AUTH_ACTIONS_FAILED, { error }),
};

export const AuthActions =
  authActions.login |
  authActions.loginSuccess |
  authActions.loginFailed |
  authActions.logout |
  authActions.logoutSuccess |
  authActions.logoutFailed |
  authActions.authorizeSuccess |
  authActions.authorizeFailed |
  authActions.loadServerAuthActions |
  authActions.loadServerAuthActionsSuccess |
  authActions.loadServerAuthActionsFailed;

export const loginAsync = (email, password) => async (dispatch) => {
  dispatch(authActions.login());

  try {
    await login(email, password);
    const users = await getUsers({ self: true });

    dispatch(authActions.loginSuccess(users));
  } catch (error) {
    resetAuthorization();
    dispatch(authActions.loginFailed(error));
  }
};

export const logoutAsync = () => async (dispatch) => {
  dispatch(authActions.logout());

  try {
    await logout();
    dispatch(authActions.logoutSuccess());
  } catch (error) {
    dispatch(authActions.logoutFailed(error));
  }
};

export const authorizedAsync = () => async (dispatch) => {
  try {
    const result = await authorized();

    if (result) {
      const userInstance = await getUsers({ self: true });
      dispatch(authActions.authorizeSuccess(userInstance));
    } else {
      dispatch(authActions.authorizeSuccess(null));
    }
  } catch (error) {
    dispatch(authActions.authorizeFailed(error));
  }
};

export const loadAuthActionsAsync = () => async (dispatch) => {
  dispatch(authActions.loadServerAuthActions());

  try {
    const promises = [
      isReachable(`auth/password/change`, 'OPTIONS'),
      isReachable(`auth/password/reset`, 'OPTIONS'),
    ];
    const [allowChangePassword, allowResetPassword] = await Promise.all(
      promises
    );

    dispatch(
      authActions.loadServerAuthActionsSuccess(
        allowChangePassword,
        allowResetPassword
      )
    );
  } catch (error) {
    dispatch(authActions.loadServerAuthActionsFailed(error));
  }
};
