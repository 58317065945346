import { useCallback, useEffect, useState } from 'react';
import { debouncedSave } from '../utils/utils';

const baseSize = {
  width: 700,
};

export const useScale = () => {
  const [isMobile, setIsMobile] = useState(false);

  const calcScale = () => {
    const width = window.innerWidth;

    if (width < baseSize.width) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    debouncedSave(() => calcScale(), 300),
    []
  );

  useEffect(() => {
    calcScale();
  }, []);

  useEffect(() => {
    window.removeEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isMobile;
};
