import { camelCase, snakeCase } from 'lodash';

const snakeToCamelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakeToCamelCase(v));
  }

  if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
          ...result,
          [camelCase(key)]: snakeToCamelCase(obj[key]),
        }),
      {},
    );
  }
  return obj;
};

const camelToSnakeCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelToSnakeCase(v));
  }

  if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      let keyTemp = snakeCase(key);
      if (['password1', 'password2', 'new_password1', 'new_password2'].includes(key)) {
        keyTemp = key;
      }
      return {
        ...result,
        [keyTemp]: camelToSnakeCase(obj[key]),
      };
    }, {});
  }
  return obj;
};

// eslint-disable-next-line import/prefer-default-export
export { snakeToCamelCase, camelToSnakeCase, camelCase };
