import { Table } from 'antd';
import React, { useEffect } from 'react';
import './styles.css';

export default function TransportDataList(props) {
  const { csvData, reset, loading } = props;
  const [pageSize, setPageSize] = React.useState(50);
  const [currentPage, setCurrentPage] = React.useState(1);

  const columns = [
    {
      title: '業者名',
      dataIndex: '業者名',
      key: '業者名',
    },
    {
      title: '業者ID',
      dataIndex: '業者ID',
      key: '業者ID',
    },
    {
      title: '設定１',
      dataIndex: '設定１',
      key: '設定１',
    },
    {
      title: '設定２',
      dataIndex: '設定２',
      key: '設定２',
    },
    {
      title: '設定３',
      dataIndex: '設定３',
      key: '設定３',
    },
    {
      title: '設定４',
      dataIndex: '設定４',
      key: '設定４',
    },
    {
      title: '設定５',
      dataIndex: '設定５',
      key: '設定５',
    },
    {
      title: '設定６',
      dataIndex: '設定６',
      key: '設定６',
    },
    {
      title: '設定７',
      dataIndex: '設定７',
      key: '設定７',
    },
    {
      title: '設定８',
      dataIndex: '設定８',
      key: '設定８',
    },
    {
      title: '設定９',
      dataIndex: '設定９',
      key: '設定９',
    },
    {
      title: '設定１０',
      dataIndex: '設定１０',
      key: '設定１０',
    },
  ];

  useEffect(() => {
    if (reset) {
      setCurrentPage(1);
    }
  }, [reset]);

  const handleChangePageSize = (current, size) => {
    setPageSize(size);
  };

  const handleChangePage = (pageNum) => {
    setCurrentPage(pageNum);
  };
  return (
    <Table
      columns={columns}
      dataSource={csvData}
      rowKey={(record) => record['業者ID']}
      style={{
        marginTop: '1em',
        overflow: 'auto',
      }}
      loading={loading}
      pagination={{
        pageSize: pageSize,
        defaultPageSize: 50,
        defaultCurrent: 1,
        current: currentPage,
        showSizeChanger: true,
        onShowSizeChange: handleChangePageSize,
        onChange: handleChangePage,
      }}
    />
  );
}
