import { Card } from 'antd';
import { Row } from 'antd/lib/grid';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginAsync } from '../../actions/auth-actions';
import LoginForm from './login-form';

function LoginPageComponent(props) {
  const { fetching, onLogin } = props;

  return (
    <>
      <Row justify='center' align='middle' style={{ height: '100vh' }}>
        <Card
          cover={
            <img
              style={{ padding: '20px' }}
              alt='No logo'
              src='/static/sy-link-logo.png'
            />
          }
          className='login-box-shadow'
        >
          <LoginForm
            fetching={fetching}
            onSubmit={(loginData) => {
              onLogin(loginData.email, loginData.password);
            }}
          />
        </Card>
      </Row>
    </>
  );
}

function mapStateToProps(state) {
  return {
    fetching: state.auth.fetching,
    renderResetPassword: state.auth.allowResetPassword,
  };
}

const mapDispatchToProps = {
  onLogin: loginAsync,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPageComponent)
);
