import Icon from '@ant-design/icons';
import { Table } from 'antd';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { DetailIcon } from '../../icons';
import './styles.css';
import { showError } from '../../utils/message';

export default function DeliverInfoList(props) {
  const { csvData, reset } = props;
  const [pageSize, setPageSize] = React.useState(50);
  const [currentPage, setCurrentPage] = React.useState(1);
  const { pathname } = useLocation();
  const isDedicatedSearch = pathname === '/dedicated-search';

  const openNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  const columns = [
    {
      title: '業者名',
      dataIndex: '業者名',
      key: '業者名',
    },
    {
      title: '問合番号',
      dataIndex: '問合番号',
      key: '問合番号',
    },
    {
      title: '問合結果',
      dataIndex: '問合結果',
      key: '問合結果',
    },
    {
      title: '問合時間',
      dataIndex: '問合時間',
      key: '問合時間',
    },
    {
      title: '結果時間',
      dataIndex: '結果時間',
      key: '結果時間',
    },
    {
      title: '最終配達ターミナル名',
      dataIndex: '最終配達ターミナル名',
      key: '最終配達ターミナル名',
    },
    {
      title: '問合せ電話番号',
      dataIndex: '問合せ電話番号',
      key: '問合せ電話番号',
    },
    {
      title: '各社サイト',
      dataIndex: '各社サイト',
      key: '各社サイト',
      render: (text) => (
        <Icon
          component={DetailIcon}
          onClick={() =>
            text === '#' ? showError('エラー', '他社サイトが特定不可のため表示できません') : openNewTab(text)
          }
        />
      ),
    },
  ];

  useEffect(() => {
    if (reset) {
      setCurrentPage(1);
    }
  }, [reset]);

  const handleChangePageSize = (current, size) => {
    setPageSize(size);
  };

  const handleChangePage = (pageNum) => {
    setCurrentPage(pageNum);
  };
  return (
    <Table
      columns={columns}
      dataSource={csvData}
      rowKey={csvData['問合番号']}
      className={isDedicatedSearch ? 'deliver-info-osaka' : ''}
      style={{
        marginTop: '1em',
        overflow: 'auto',
      }}
      pagination={{
        pageSize: pageSize,
        defaultPageSize: 50,
        defaultCurrent: 1,
        current: currentPage,
        showSizeChanger: true,
        onShowSizeChange: handleChangePageSize,
        onChange: handleChangePage,
      }}
    />
  );
}
