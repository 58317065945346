import { debounce } from 'lodash';
import { VALIDATE_PATTERNS } from './validations';

export const replaceCharacterNotAllow = (value) => {
  const returnValue = [];
  if (value) {
    [...value].forEach((s) => {
      if (VALIDATE_PATTERNS.halfSizeAlphaNumeric.test(s)) {
        returnValue.push(s);
      }
    });
  }
  return returnValue.join('');
};

export const debouncedSave = (func, delay) => {
  return debounce(func, delay);
};

export const hasPermission = (user, value) => {
  const { authorization } = user?.authorizations || { authorization: [] };
  return authorization && (authorization.includes(value) || authorization.includes(5));
};

export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    // eslint-disable-next-line use-isnan
    Number.isNaN(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

export const numberWithCommas = (x, delimiter = ',') => {
  if (isEmpty(x)) return '';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
};
