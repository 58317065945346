import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

function footerOsaka() {
  return (
    <Footer style={{ textAlign: 'center' }}>
      powered by{' '}
      <a href='https://sy-link.jp/itsukuru1/' target='_blank' rel='noreferrer'>
        イツクル
      </a>
    </Footer>
  );
}

export default footerOsaka;
