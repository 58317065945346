import { showError } from '../utils/message';
import httpCommon from './http-common';
import store from 'store';
const REGEX_TRACK_NUMBER = /([A-Za-z0-9\n])*/g;

const getNumber = (value) => {
  const temp = value.match(REGEX_TRACK_NUMBER);
  return temp.join('');
};

const getDeliveryInfo = async (
  ids,
  isFree,
  transport,
  updateStatus,
  showData,
  requestTime,
  isDedicatedSearch,
  tid,
  trackingNumberColumn,
  transportColumn,
  companyCodeColumn,
  haveHeader
) => {
  if (isFree || isDedicatedSearch) {
    const temp = ids.split('\n').map((id) => getNumber(id));
    ids = temp.join(',');
  } else {
    ids = ids.join(',');
  }
  await httpCommon.post('delivery-info/', {
    id: ids,
    isFree: isFree ? true : '',
    transport,
    tid,
    isDedicatedSearch: isDedicatedSearch ? true : '',
    trackingNumberColumn,
    transportColumn,
    companyCodeColumn,
    haveHeader: JSON.stringify(haveHeader)
  });

  return wait(tid, updateStatus, showData, requestTime);
};

const wait = async (id, updateStatus, showData, requestTime) => {
  return new Promise((resolve, reject) => {
    const checkStatus = async () => {
      try {
        const response = await getStatus(id);
        const { state, message } = response.data;
        if (['Queued', 'Started'].includes(state)) {
          if (message !== '') {
            updateStatus(message);
            showData(response.data, requestTime);
          }
          setTimeout(checkStatus, 1000);
        } else if (state === 'Finished') {
          if (message !== '') {
            updateStatus(message);
          }
          resolve(response.data);
        } else if (state === 'Canceled') {
          if (message !== '') {
            updateStatus(message);
          }
          setTimeout(checkStatus, 1000);
        } else if (state === 'Failed') {
          if (message !== '') {
            updateStatus(
              message.includes('送り状番号')
                ? '該当する伝票番号の、追跡結果が見つかりませんでした。伝票番号をご確認いただくかお問い合わせください。'
                : 'エラーが発生しています。管理会社にお問い合わせください。',
            );
            message.includes('CSVファイルに記載されている「業者コード」が正しくありません。') &&
              showError('エラー', 'CSVファイルに記載されている「業者コード」が正しくありません。');
          }
          resolve(response.data);
          // const message = 'Could not get delivery info on the server. ';
          // reject(new Error(message, 400));
        } else {
          reject(new Error('Unknow job state has been received', 500));
        }
      } catch (error) {
        reject(error);
      }
    };
    setTimeout(checkStatus, 1000);
  });
};

const getStatus = (id) => {
  return httpCommon.get('get-status/', {
    params: {
      id: id,
    },
  });
};

const cancelJob = (id) => {
  return httpCommon.get('cancel-job/', {
    params: {
      id: id,
    },
  });
};

const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await httpCommon.post('upload/', formData);
  return response.data;
};

const uploadFileLimit = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await httpCommon.post('upload-limit/', formData);
  return response.data;
};

const uploadLinkedData = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await httpCommon.post('upload-linked-data/', formData);
  return response.data;
};

const uploadTransportData = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await httpCommon.post('upload-transport-info/', formData);
  return response.data;
};

const resetAuthorization = () => {
  store.remove('token');
  httpCommon.defaults.headers.common['Authorization'] = '';
};

const login = async (email, password) => {
  let authenticationResponse = null;
  httpCommon.defaults.headers.common.Authorization = '';
  authenticationResponse = await httpCommon.post(`login`, {
    email,
    password,
  });
  if (authenticationResponse.headers['set-cookie']) {
    const cookies = authenticationResponse.headers['set-cookie'].join(';');
    httpCommon.defaults.headers.common.Cookie = cookies;
  }

  const token = authenticationResponse.data.key;
  store.set('token', token);
  httpCommon.defaults.headers.common.Authorization = `Token ${token}`;
};

const logout = async () => {
  await httpCommon.post(`logout`);
  resetAuthorization();
};

const authorized = async () => {
  try {
    await getSelf();
  } catch (serverError) {
    resetAuthorization();
    if (serverError.response.status === 401) {
      return false;
    }

    throw serverError;
  }

  return true;
};

const getUsers = async (filter) => {
  let users = null;
  if ('self' in filter && filter.self) {
    users = await getSelf();
  } else {
    users = await getUserList();
  }

  return users;
};

const getUserList = async (id = null) => {
  let response = null;
  if (id === null) {
    response = await httpCommon.get(`users?page_size=all`);
  } else {
    response = await httpCommon.get(`users/${id}`);
  }

  return response.data.results || response.data;
};

const getSelf = async () => {
  const response = await httpCommon.get(`users/self`);
  return response.data;
};

const serverRequest = async (url, data) => {
  const response = (await httpCommon.options(url, ...data)).data;
  return response;
};

const getFilesLinkedData = async () => {
  const response = await httpCommon.get(`files?page_size=all`);
  return response.data.results;
};

const getTransportInfoData = async () => {
  const response = await httpCommon.get(`transports?page_size=all`);
  return response.data.results;
};

const exportTransportData = async () => {
  const response = await httpCommon.get(`export/transport-info`, {
    responseType: 'blob',
  });
  return response;
};

const deleteFileLinkedData = async (id) => {
  await httpCommon.delete(`files/${id}`);
};

const getTransportCategories = async () => {
  const response = await httpCommon.get(`get-transport-category`);
  return response.data;
};

export {
  getDeliveryInfo,
  login,
  logout,
  authorized,
  serverRequest,
  getUsers,
  uploadFile,
  uploadFileLimit,
  resetAuthorization,
  cancelJob,
  uploadLinkedData,
  getFilesLinkedData,
  deleteFileLinkedData,
  getTransportInfoData,
  uploadTransportData,
  exportTransportData,
  getTransportCategories,
};
