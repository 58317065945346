import { InboxOutlined } from '@ant-design/icons';
import { Col, message, Upload } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  deleteFileLinkedData,
  getFilesLinkedData,
  uploadLinkedData,
} from '../../services/api-service';
import { showError, showMessage } from '../../utils/message';
import { debouncedSave } from '../../utils/utils';
import LinkedDataList from './linked-data-list';

const { Dragger } = Upload;

export default function UploadFile() {
  const [csvData, setCsvData] = useState([]);
  const uploadFiles = useRef([]);
  const [loading, setLoading] = useState(false);
  const [resetCurrentPage, setResetCurrentPage] = useState(false);
  const maxFile = 5;

  const handleGetData = () => {
    setLoading(true);
    getFilesLinkedData()
      .then((response) => {
        showData(response);
      })
      .catch((error) => {
        showError(
          'エラー',
          error?.response?.data?.recordLimit ||
            '管理会社にお問い合わせください。'
        );
      })
      .finally(() => {
        setLoading(false);
        setResetCurrentPage(true);
        uploadFiles.current = []
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceApi = useCallback(debouncedSave(handleGetData, 1000), []);

  const isExist = (file) => {
    return (
      uploadFiles.current.filter((item) => item.uid === file.uid).length > 0
    );
  };

  const dumpRequest = async ({ file, onSuccess, onError }) => {
    if (isExist(file)) {
      const response = uploadLinkedData(file);
      response
        .then((result) => onSuccess('ok', result))
        .catch((error) => {
          if (error.response?.data?.file[0]) {
            message.error(error.response?.data?.file[0]);
          } else {
            message.error(`File upload failed.`);
          }
          onError(`File upload failed.`);
        });
    } else {
      onSuccess('ok');
    }
  };

  const props = {
    name: 'file',
    multiple: true,
    accept: '.csv',
    maxCount: maxFile,
    onChange(info) {
      const { status } = info.file;
      const { fileList } = info;
      uploadFiles.current = fileList;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        console.log('call api');
        debounceApi();
      }
    },
    customRequest: dumpRequest,
    showUploadList: false,
  };

  moment.locale('ja');

  useEffect(() => {
    setLoading(true);
    getFilesLinkedData()
      .then((response) => {
        showData(response);
      })
      .catch((error) => {
        showError(
          'エラー',
          error?.response?.data?.recordLimit ||
            '管理会社にお問い合わせください。'
        );
      })
      .finally(() => {
        setLoading(false);
        setResetCurrentPage(true);
      });
  }, []);

  const showData = (response) => {
    if (!response) return;
    if (Array.isArray(response)) {
      const temp = response.map((item) => {
        return {
          id: item.id,
          日付: item.createDate,
          ファイル名: item.fileName,
          操作: item.id,
        };
      });
      setCsvData(temp);
    }
  };

  const handleDelete = (id) => {
    setLoading(true);
    deleteFileLinkedData(id)
      .then(() => {
        showMessage('ファイルを削除しました。');
        handleGetData();
      })
      .catch((error) => showError('エラー', '失敗に削除しました。'))
      .finally(() => setLoading(false));
  };

  return (
    <Col
      style={{
        height: '100%',
      }}
    >
      <>
        <Dragger
          {...props}
          style={{
            marginBottom: '2em',
          }}
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            紐付けデータアップロードエリア
            <br />
            この領域にクリックしてファイルを選択するか、又はファイルをドラッグしてアップロードします。
            <br />
            ※CSVファイルは6件以上アップロードできません。
          </p>
        </Dragger>
      </>

      <LinkedDataList
        csvData={csvData}
        reset={resetCurrentPage}
        handleDelete={handleDelete}
        loading={loading}
      />
    </Col>
  );
}
