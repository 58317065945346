import Icon, { CaretDownOutlined, LoadingOutlined, LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import Dropdown from 'antd/lib/dropdown';
import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { logoutAsync } from '../../actions/auth-actions';
import { useScale } from '../../hooks/scale';
import { SysLinkLogo } from '../../icons';
import ResponsiveMenu from './responsive-menu';

import { hasPermission } from '../../utils/utils';
import './styles.css';

function mapStateToProps(state) {
  const {
    auth: {
      user,
      fetching: logoutFetching,
      fetching: changePasswordFetching,
      allowChangePassword: renderChangePasswordItem,
    },
  } = state;

  return {
    user,
    changePasswordFetching,
    logoutFetching,
    renderChangePasswordItem,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLogout: () => dispatch(logoutAsync()),
  };
}

function HeaderContainer(props) {
  const { user, logoutFetching, onLogout, pathname } = props;
  const isMobile = useScale();
  const [visible, setVisible] = useState(false);
  const isShowMenu = hasPermission(user, 2);
  const isSpecial = pathname === '/upload-transport-data';
  console.log(isSpecial);
  const menu = (
    <Menu className='delivery-header-menu' mode='vertical'>
      <Menu.Item onClick={onLogout} disabled={logoutFetching}>
        {logoutFetching ? <LoadingOutlined /> : <LogoutOutlined />}
        ログアウト
      </Menu.Item>
    </Menu>
  );

  const history = useHistory();

  const returnHome = () => history.push('/');

  return (
    <Layout.Header
      className={isSpecial ? 'delivery-header-special' : 'delivery-header'}
      style={{
        position: 'fixed',
        zIndex: 999,
        width: '100%',
      }}
    >
      <div className='delivery-left-header'>
        <Icon style={{ paddingTop: '5px' }} component={SysLinkLogo} onClick={returnHome} />
        <span onClick={returnHome}>
          <Text className='delivery-header-logo-text '>荷物追跡ツール</Text>
        </span>
      </div>
      <div className='delivery-right-header'>
        {isMobile ? (
          <MenuOutlined onClick={() => setVisible((prev) => !prev)} />
        ) : (
          <>
            {isShowMenu && (
              <Link to='/upload-transport-data' target='_blank' style={{ marginRight: '20px' }}>
                <Text className='delivery-header-text' strong>
                  業者コード紐付け
                </Text>
              </Link>
            )}

            <Link to='/delivery-list-trial' target='_blank'>
              <Text className='delivery-header-text' strong>
                簡易検索
              </Text>
            </Link>
            <Dropdown overlay={menu} className='delivery-header-menu-dropdown'>
              <span>
                <Text className='delivery-header-text' strong>
                  {user.firstName.length > 14 ? `${user.firstName.slice(0, 10)} ...` : user.firstName}
                </Text>
                <CaretDownOutlined className='delivery-header-menu-icon' />
              </span>
            </Dropdown>
          </>
        )}
      </div>
      <Drawer placement='left' closable={false} onClose={() => setVisible((prev) => !prev)} visible={visible}>
        <ResponsiveMenu user={user} menu={menu} />
      </Drawer>
    </Layout.Header>
  );
}

function propsAreTheSame(prevProps, nextProps) {
  let equal = true;
  for (const prop in nextProps) {
    if (prop in prevProps && prevProps[prop] !== nextProps[prop]) {
      if (prop !== 'tool') {
        equal = false;
      }
    }
  }

  return equal;
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(HeaderContainer, propsAreTheSame));
