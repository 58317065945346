import { Button, Table } from 'antd';
import React, { useEffect } from 'react';
import './styles.css'

export default function LinkedDataList(props) {
  const { csvData, reset, handleDelete, loading } = props;
  const [pageSize, setPageSize] = React.useState(50);
  const [currentPage, setCurrentPage] = React.useState(1);

  const columns = [
    {
      title: '日付',
      dataIndex: '日付',
      key: '日付',
    },
    {
      title: 'ファイル名',
      dataIndex: 'ファイル名',
      key: 'ファイル名',
    },
    {
      title: '操作',
      dataIndex: '操作',
      key: '操作',
      render: (id) => (
        <Button
          type='link'
          onClick={() => handleDelete(id)}
          style={{ padding: 0 }}
        >
          削除
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (reset) {
      setCurrentPage(1);
    }
  }, [reset]);

  const handleChangePageSize = (current, size) => {
    setPageSize(size);
  };

  const handleChangePage = (pageNum) => {
    setCurrentPage(pageNum);
  };
  return (
    <Table
      columns={columns}
      dataSource={csvData}
      className='deliver-info-osaka'
      rowKey={(record) => record.id}
      style={{
        marginTop: '1em',
        overflow: 'auto',
      }}
      loading={loading}
      pagination={{
        pageSize: pageSize,
        defaultPageSize: 50,
        defaultCurrent: 1,
        current: currentPage,
        showSizeChanger: true,
        onShowSizeChange: handleChangePageSize,
        onChange: handleChangePage,
      }}
    />
  );
}
