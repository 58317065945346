import { LockOutlined, UserAddOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import React from 'react';
import { useHistory } from 'react-router';

export default function LoginFormComponent(props) {
  const [form] = Form.useForm();
  const { fetching, onSubmit } = props;
  const history = useHistory();

  const renderEmailField = () => {
    return (
      <Form.Item
        name='email'
        rules={[
          {
            required: true,
            message: 'メールを入力してください。',
          },
        ]}
      >
        <Input
          autoComplete='email'
          prefix={<UserAddOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder='Email'
        />
      </Form.Item>
    );
  };

  const renderPasswordField = () => {
    return (
      <Form.Item
        name='password'
        rules={[
          {
            required: true,
            message: 'パースワードを入力してください。',
          },
        ]}
      >
        <Input
          autoComplete='current-password'
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder='Password'
          type='password'
        />
      </Form.Item>
    );
  };

  const renderLinkTrial = () => {
    return (
      <Form.Item>
        <Button
          type='link'
          onClick={() => history.push('/delivery-list-trial')}
        >
          ログイン無しでフリー使用したい方
        </Button>
      </Form.Item>
    );
  };

  const onFinish = (values) => {
    onSubmit(values);
  };

  return (
    <Form form={form} onFinish={onFinish} className='login-form'>
      {renderEmailField()}
      {renderPasswordField()}
      <Form.Item>
        <Button
          type='primary'
          loading={fetching}
          disabled={fetching}
          style={{ width: '100%' }}
          htmlType='submit'
          className='login-form-button'
        >
          ログイン
        </Button>
      </Form.Item>
      {renderLinkTrial()}
    </Form>
  );
}
