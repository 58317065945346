import './styles.css';

import Icon from '@ant-design/icons';
import Layout from 'antd/lib/layout';
import React from 'react';
import { useHistory } from 'react-router';

import { OsakaLinkLogo } from '../../icons';

function HeaderOsakaContainer(props) {
  const history = useHistory();
  const returnHome = () => {
    history.push('/dedicated-search');
  };

  return (
    <Layout.Header
      className='delivery-osaka-header'
      style={{ position: 'fixed', zIndex: 999, width: '100%' }}
    >
      <div className='delivery-left-header'>
        <Icon
          component={OsakaLinkLogo}
          onClick={returnHome}
        />
      </div>
    </Layout.Header>
  );
}

export default React.memo(HeaderOsakaContainer);
