import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

function footer() {
  return (
    <Footer style={{ textAlign: 'center' }}>© 2020 株式会社SY-Link</Footer>
  );
}

export default footer;
