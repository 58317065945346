import Icon from '@ant-design/icons';
import Layout from 'antd/lib/layout';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { SysLinkLogo } from '../../icons';
import './styles.css';

function HeaderContainer(props) {
  const history = useHistory();
  const { pathname } = useLocation();
  const isDedicatedSearch = pathname === '/dedicated-search';

  const returnHome = () => {
    if (!isDedicatedSearch) history.push('/');
  };

  return (
    <Layout.Header
      className='delivery-header'
      style={{ position: 'fixed', zIndex: 999, width: '100%' }}
    >
      <div className='delivery-left-header'>
        <Icon
          style={{ paddingTop: '5px' }}
          component={SysLinkLogo}
          onClick={returnHome}
        />
        <span onClick={returnHome}>
          <Text className='delivery-header-logo-text '>荷物追跡ツール</Text>
        </span>
      </div>
    </Layout.Header>
  );
}

export default React.memo(HeaderContainer);
