import { serverRequest } from '../services/api-service';

// eslint-disable-next-line import/no-anonymous-default-export
export default async (url, method) => {
  try {
    await serverRequest(url, {
      method,
    });
    return true;
  } catch (error) {
    return ![0, 404].includes(error.code);
  }
};
