import axios from 'axios';
import store from 'store';
import { camelToSnakeCase, snakeToCamelCase } from '../utils/convertKeys';

// export const baseURL = 'https://itsukuru.site/api/';
export const baseURL =
  typeof process.env['REACT_APP_API_URL'] === 'undefined' ? '/api' : `${process.env['NX_REACT_APP_API_URL']}/api`;
// export const baseURL = 'https://test-crawl-deliver-info.herokuapp.com/api/';

const httpCommon = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
httpCommon.defaults.withCredentials = true;
httpCommon.defaults.xsrfCookieName = 'csrftoken';
httpCommon.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

const token = store.get('token');
if (token) {
  httpCommon.defaults.headers.common.Authorization = `Token ${token}`;
}

httpCommon.interceptors.response.use(
  (response) => {
    response.data = snakeToCamelCase(response.data);
    return response;
  },
  (error) => {
    error.response.data = snakeToCamelCase(error.response.data);
    return Promise.reject(error);
  },
);

httpCommon.interceptors.request.use(
  (config) => {
    config.params = camelToSnakeCase(config.params);
    config.data = camelToSnakeCase(config.data);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default httpCommon;
