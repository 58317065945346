import { AuthActionTypes } from '../actions/auth-actions';
import { NotificationsActionType } from '../actions/notification-actions';

const defaultState = {
  errors: {
    auth: {
      authorized: null,
      login: null,
      logout: null,
      register: null,
      changePassword: null,
      requestPasswordReset: null,
      resetPassword: null,
      loadAuthActions: null,
    },
  },
  messages: {
    auth: {
      changePasswordDone: '',
      registerDone: '',
      requestPasswordResetDone: '',
      resetPasswordDone: '',
    },
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultState, action) {
  switch (action.type) {
    case AuthActionTypes.AUTHORIZED_FAILED: {
      return {
        ...state,
        errors: {
          ...state.errors,
          auth: {
            ...state.errors.auth,
            authorized: {
              message: 'Could not check authorization on the server',
              reason: action.payload.error.toString(),
            },
          },
        },
      };
    }
    case AuthActionTypes.LOGIN_FAILED: {
      return {
        ...state,
        errors: {
          ...state.errors,
          auth: {
            ...state.errors.auth,
            login: {
              message: 'Could not login on the server',
              reason: action.payload.error.toString(),
            },
          },
        },
      };
    }
    case AuthActionTypes.LOGOUT_FAILED: {
      return {
        ...state,
        errors: {
          ...state.errors,
          auth: {
            ...state.errors.auth,
            logout: {
              message: 'Could not logout from the server',
              reason: action.payload.error.toString(),
            },
          },
        },
      };
    }
    case AuthActionTypes.LOAD_AUTH_ACTIONS_FAILED: {
      return {
        ...state,
        errors: {
          ...state.errors,
          auth: {
            ...state.errors.auth,
            loadAuthActions: {
              message: 'Could not check available auth actions',
              reason: action.payload.error.toString(),
            },
          },
        },
      };
    }

    case NotificationsActionType.RESET_ERRORS: {
      return {
        ...state,
        errors: {
          ...defaultState.errors,
        },
      };
    }
    case NotificationsActionType.RESET_MESSAGES: {
      return {
        ...state,
        messages: {
          ...defaultState.messages,
        },
      };
    }
    case AuthActionTypes.LOGOUT_SUCCESS: {
      return { ...defaultState };
    }
    default: {
      return state;
    }
  }
}
