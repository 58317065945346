import { combineReducers } from 'redux';
import authReducer from './auth-reducer';
import notificationsReducer from './notifications-reducer';

export default function createRootReducer() {
  return combineReducers({
    auth: authReducer,
    notifications: notificationsReducer,
  });
}
