import 'antd/dist/antd.css';
import Layout from 'antd/lib/layout';
import Spin from 'antd/lib/spin';
import React from 'react';
import { configure, GlobalHotKeys } from 'react-hotkeys';
import { Redirect, Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import '../index.css';
import { customWaViewHit } from '../utils/environment';
import { showError, showMessage } from '../utils/message';
import DeliveryPageContainer from './delivery-page/delivery-page-container';
import UploadLinkedDataPageContainer from './delivery-page/upload-linked-data-page-container';
import UploadTransportTypeDataPageContainer from './delivery-page/upload-transport-type-data-page-container';
import Footer from './footer/footer';
import FooterOsaka from './footer/footer-osaka';
import Header from './header/header';
import HeaderFree from './header/header-free';
import HeaderOsaka from './header/header-osaka';
import LoginPageContainer from './login-page/login-page';
import LoginWithTokenComponent from './login-with-token/login-with-token';

class DeliveryApplication extends React.PureComponent {
  componentDidMount() {
    const { verifyAuthorized, history, location } = this.props;
    configure({ ignoreRepeatedEventsWhenKeyHeldDown: false });

    // Logger configuration
    const userActivityCallback = [];
    window.addEventListener('click', () => {
      userActivityCallback.forEach((handler) => handler());
    });

    customWaViewHit(location.pathname, location.search, location.hash);
    history.listen((_location) => {
      customWaViewHit(_location.pathname, _location.search, _location.hash);
    });

    verifyAuthorized();

    if (location.pathname === '/dedicated-search' || location.pathname === '/upload-linked-data') {
      const favicon = document.getElementById('link-icon');
      favicon.href = '/static/osaka-favicon.png';
      const appTitle = document.getElementById('app-title');
      appTitle.innerText = '大阪商運　発送状況確認';
    }
  }

  componentDidUpdate() {
    const {
      verifyAuthorized,
      loadAuthActions,
      userInitialized,
      userFetching,
      user,
      authActionsFetching,
      authActionsInitialized,
    } = this.props;

    this.showErrors();
    this.showMessages();

    if (!userInitialized && !userFetching) {
      verifyAuthorized();
      return;
    }

    if (user == null || user.emailVerificationRequired) {
      return;
    }

    if (!authActionsInitialized && !authActionsFetching) {
      loadAuthActions();
    }
  }

  showMessages = () => {
    const { notifications, resetMessages } = this.props;

    let shown = false;
    for (const where of Object.keys(notifications.messages)) {
      for (const what of Object.keys(notifications.messages[where])) {
        const message = notifications.messages[where][what];
        shown = shown || !!message;
        if (message) {
          showMessage(message);
        }
      }
    }

    if (shown) {
      resetMessages();
    }
  };

  showErrors = () => {
    const { notifications, resetErrors } = this.props;

    let shown = false;
    for (const where of Object.keys(notifications.errors)) {
      for (const what of Object.keys(notifications.errors[where])) {
        const error = notifications.errors[where][what];
        shown = shown || !!error;
        if (error) {
          showError(error.message, error.reason);
        }
      }
    }

    if (shown) {
      resetErrors();
    }
  };

  // Where you go depends on your URL
  render = () => {
    const { userInitialized, user } = this.props;
    const readyForRender = userInitialized && (user == null || user);
    if (readyForRender) {
      if (user && !user.emailVerificationRequired) {
        return (
          <Layout className='layout'>
            <Header className='header' pathname={this.props.history.location.pathname}/>
            <Layout.Content
              style={{
                height: '100%',
                padding: '40px 40px',
                marginTop: '60px',
              }}
            >
              <GlobalHotKeys>
                <Switch>
                  <Route exact path='/delivery-list' component={DeliveryPageContainer} />
                  <Route exact path='/delivery-list-trial' component={DeliveryPageContainer} />
                  <Route exact path='/upload-transport-data' component={UploadTransportTypeDataPageContainer} />
                  <Redirect push to='/delivery-list' />
                </Switch>
              </GlobalHotKeys>
            </Layout.Content>
            <Footer />
          </Layout>
        );
      }

      return this.props.history.location.pathname === '/delivery-list-trial' ||
        this.props.history.location.pathname === '/dedicated-search' ||
        this.props.history.location.pathname === '/upload-linked-data' ? (
        <Layout className='layout'>
          {this.props.history.location.pathname === '/dedicated-search' ||
          this.props.history.location.pathname === '/upload-linked-data' ? (
            <HeaderOsaka className='header' />
          ) : (
            <HeaderFree className='header' />
          )}
          <Layout.Content
            style={{
              height: '100%',
              padding: '40px 40px',
              marginTop: '60px',
            }}
          >
            <GlobalHotKeys>
              <Switch>
                <Route exact path='/delivery-list-trial' component={DeliveryPageContainer} />

                <Route exact path='/dedicated-search' component={DeliveryPageContainer} />
                <Route exact path='/upload-linked-data' component={UploadLinkedDataPageContainer} />
                <Redirect to='/auth/login' />
              </Switch>
            </GlobalHotKeys>
          </Layout.Content>
          {this.props.history.location.pathname === '/dedicated-search' ||
          this.props.history.location.pathname === '/upload-linked-data' ? (
            <FooterOsaka className='header' />
          ) : (
            <Footer />
          )}
        </Layout>
      ) : (
        <GlobalHotKeys>
          <Switch>
            <Route exact path='/auth/login' component={LoginPageContainer} />
            <Route exact path='/auth/login-with-token/:sessionId/:token' component={LoginWithTokenComponent} />
            <Route exact path='/check-healthy' component={() => <div>OK</div>} />
            <Redirect to='/auth/login' />
          </Switch>
        </GlobalHotKeys>
      );
    }

    return <Spin size='large' className='delivery-spinner' />;
  };
}

export default withRouter(DeliveryApplication);
